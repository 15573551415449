/* eslint-disable */
// mfe-components/src/index.js
import { h, createApp, reactive } from "vue";
import singleSpaVue from "single-spa-vue";
import Button from "primevue/button";
import { createApolloClient } from "@nhost/apollo";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { NhostClient } from "@nhost/vue";
import i18n from "@/../i18n";
import router from "@/router/index.ts";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import Toast from "primevue/toast";


// require("@/../public/theme/carrefour/tables-theme.css");
// require("@/../public/theme/denim/theme-light.css");
// require("@/../public/layout/css/layout-light.css");

import App from "./App.vue";

import { subdomain, region } from "../env.js";

const nhost = new NhostClient({
  subdomain,
  region,
});
export const apolloClient = createApolloClient({ nhost });

let toastG;
let pinia = createPinia();

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      });
    },
  },
  handleInstance(app) {
    // eslint-disable-next-line no-undef
    toastG = app.config.globalProperties.$toast;
    // eslint-disable-next-line no-undef
    app.provide(DefaultApolloClient, apolloClient);
    app.config.globalProperties.$appState = reactive({
      isRTL: false,
      isNewThemeLoaded: false,
      layoutMode: "light",
    });    
    app.use(PrimeVue, { ripple: true });
    app.use(pinia);
    app.use(router);
    app.use(i18n);
    app.use(nhost);

    app.component("Button", Button);
    // eslint-disable-next-line
    app.component("Toast", Toast);
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
export { toastG, pinia, nhost };